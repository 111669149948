<template>
  <div>
    <p class="calc-mb-3">{{ name }}</p>

    <input
      type="number"
      :value="value"
      :min="min"
      :max="max"
      @input="checkVal(+$event.target.value)"
      class="calc-prop-input"
    >
  </div>
</template>

<script>
export default {
  name: 'PropInput',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    min: {
      type: Number,
      required: true,
      default: 0,
    },
    max: {
      type: Number,
      required: true,
      default: 100,
    },
    price: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  methods: {
    checkVal(val) {
      let value = 0;

      if (val && val >= this.min && val <= this.max) {
        value = val;
      } else if (val && val > this.max) {
        value = this.max;
      } else {
        value = this.min;
      }

      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss">
  .calc-prop-input {
    width: 100%;
    // max-width: 340px;
    height: 64px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    padding: 15px;
    outline: none;
    box-sizing: border-box;
    font-weight: inherit;
    font-size: inherit;
    color: #232323;

    @media (max-width: 720px) {
      height: 48px;
      padding: 10px;
    }
  }
</style>
