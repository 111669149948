<template>
  <Radio
    :list="filterList"
    name="vat"
    :value="value"
    @input="$emit('input', $event)"
  ></Radio>
</template>

<script>
import Radio from '@/components/Radio.vue';

export default {
  name: 'VatSelect',
  components: {
    Radio,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    value: {
      type: [String, null],
    },
  },
  data: () => ({
    list: [
      {
        name: 'ОСНО',
        value: 'osno',
        type: ['ip', 'ooo'],
      },
      {
        name: 'УСНО -6%',
        value: 'usno-6',
        type: ['ip', 'ooo'],
      },
      {
        name: 'УСНО -15%',
        value: 'usno-15',
        type: ['ip', 'ooo'],
      },
      {
        name: 'Патент',
        value: 'patent',
        type: ['ip'],
      },
    ],
  }),
  computed: {
    filterList() {
      return this.list.filter((item) => item.type.includes(this.type));
    },
  },
};
</script>

<style>

</style>
