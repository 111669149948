<template>
  <div class="calc-radio">
    <template v-for="(item, index) in list">
      <div
        class="calc-radio__item"
        :class="(text) ? 'calc-radio__item--text' : ''"
        :key="`radio-${name}-${item.value}`"
      >
        <input
          type="radio"
          :name="name"
          :id="`value-${name}-${index}`"
          :value="item.value"
          :checked="value === item.value"
          :key="`radio-${name}-${item.value}`"
          @click="$emit('input', item.value)"
        />

        <label :for="`value-${name}-${index}`">
          {{ item.name }}
        </label>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Radio',
  props: {
    list: {
      type: Array,
      default: () => [],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, null],
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
  .calc-radio {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__item {
      margin: 0 15px 15px 0;

      label {
        display: inline-flex;
        min-width: 88px;
        height: 58px;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #006298;
        border: 1px solid #006298;
        border-radius: 50px;
        background: #fff;
        padding: 5px 30px;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        transition: background-color .25s ease-in-out,
                    color .25s ease-in-out;

        @media (max-width: 720px) {
          font-size: 18px;
          line-height: 22px;
          height: 48px;
          padding: 5px 10px;
        }
      }

      input {
        display: none;

        &:checked + label {
          background: #006298;
          color: #fff;
        }
      }

      &--text {
        margin-right: 0;
        margin-bottom: 30px;
        width: 100%;

        label {
          height: auto;
          min-width: initial;
          border: none;
          background: transparent;
          color: #232323;
          font-size: 24px;
          padding: 0;

          &:before {
            content: '';
            width: 28px;
            height: 28px;
            border-radius: 50%;
            margin: 2px 20px 2px 2px;
            border: 5px solid #fff;
            background: #fff;
            box-shadow: 0 0 0 2px #A4A4A4;
            box-sizing: border-box;
            transition: background-color .25s ease-in-out,
                        box-shadow .25s ease-in-out;
          }

          @media (max-width: 1199px) {
            font-size: 22px;
          }

          @media (max-width: 720px) {
            font-size: 18px;
          }
        }

        input {

          &:checked + label {
            background: transparent;
            color: #232323;

            &:before {
              box-shadow: 0 0 0 2px #006298;
              background: #006298;
            }
          }
        }

        @media (max-width: 720px) {
          margin-bottom: 15px;
        }
      }
    }
  }
</style>
