<template>
  <Radio
    :list="list"
    name="type"
    :value="value"
    @input="$emit('input', $event)"
  ></Radio>
</template>

<script>
import Radio from '@/components/Radio.vue';

export default {
  name: 'TypeSelect',
  components: {
    Radio,
  },
  props: {
    value: {
      type: [String, null],
    },
  },
  data: () => ({
    list: [
      {
        name: 'ИП',
        value: 'ip',
      },
      {
        name: 'ООО',
        value: 'ooo',
      },
    ],
  }),
};
</script>

<style>

</style>
