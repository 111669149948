<template>
  <div id="calc">
    <div class="calc-block">
      <div>
        <p class="calc-mb-3">Форма собственности</p>

        <TypeSelect
          v-model="type"
          @input="vat = 'osno'"
          class="calc-mb-4"
        ></TypeSelect>

        <p class="calc-mb-3">Организационная форма</p>

        <VatSelect
          v-model="vat"
          :type="type"
        ></VatSelect>
      </div>

      <div>
        <p class="calc-mb-3">Отрасль</p>

        <ServiceSelect
          v-model="service"
        ></ServiceSelect>
      </div>

      <div>
        <PropInput
          v-model="staff"
          name="Колличество сотрудников"
          :min="staffProps.min"
          :max="staffProps.max"
          :price="staffProps.price"
          class="calc-mb-4"
        ></PropInput>

        <PropInput
          v-model="transaction"
          name="Колличество операций"
          :min="transactionProps.min"
          :max="transactionProps.max"
          :price="transactionProps.price"
          class="calc-mb-4"
        ></PropInput>

        <PropInput
          v-model="paymentAccount"
          name="Колличество расчетных счетов"
          :min="paymentAccountProps.min"
          :max="paymentAccountProps.max"
          :price="paymentAccountProps.price"
          class="calc-mb-4"
        ></PropInput>

        <PropInput
          v-model="server1c"
          name="Обновление и хранение на сервере базы 1С"
          :min="server1cProps.min"
          :max="server1cProps.max"
          :price="server1cProps.price"
          v-if="server1cProps"
          class="calc-mb-4"
        ></PropInput>
      </div>

      <div>
        Общая стоимость

        <input
          type="number"
          :value="total"
          disabled
          class="calc-prop-input"
        >
      </div>
    </div>
  </div>
</template>

<script>
import TypeSelect from '@/components/TypeSelect.vue';
import VatSelect from '@/components/VatSelect.vue';
import ServiceSelect from '@/components/ServiceSelect.vue';
import PropInput from '@/components/PropInput.vue';

export default {
  name: 'Calc',
  components: {
    TypeSelect,
    VatSelect,
    ServiceSelect,
    PropInput,
  },
  data: () => ({
    type: 'ip',
    vat: 'osno',
    service: 'uslugi',
    staff: 0,
    transaction: 0,
    paymentAccount: 0,
    server1c: 0,
    basePriceList: [
      {
        type: 'ip',
        vat: 'osno',
        value: 2500,
      },
      {
        type: 'ip',
        vat: 'usno-6',
        value: 1500,
      },
      {
        type: 'ip',
        vat: 'usno-15',
        value: 2000,
      },
      {
        type: 'ip',
        vat: 'patent',
        value: 1500,
      },
      {
        type: 'ooo',
        vat: 'osno',
        value: 5000,
      },
      {
        type: 'ooo',
        vat: 'usno-6',
        value: 3000,
      },
      {
        type: 'ooo',
        vat: 'usno-15',
        value: 4000,
      },
    ],
    serviceRatioList: [
      {
        service: 'uslugi',
        value: 1,
      },
      {
        service: 'torgovlya',
        value: 1.2,
      },
      {
        service: 'stroitelstvo',
        value: 1.5,
      },
      {
        service: 'ved',
        value: 2,
      },
    ],
    staffPropsList: [
      {
        type: 'ip',
        vat: 'osno',
        value: {
          price: 0,
          min: 0,
          max: 40,
        },
      },
      {
        type: 'ip',
        vat: 'usno-6',
        value: {
          price: 500,
          min: 0,
          max: 40,
        },
      },
      {
        type: 'ip',
        vat: 'usno-15',
        value: {
          price: 600,
          min: 0,
          max: 40,
        },
      },
      {
        type: 'ip',
        vat: 'patent',
        value: {
          price: 500,
          min: 0,
          max: 40,
        },
      },
      {
        type: 'ooo',
        vat: 'osno',
        value: {
          price: 500,
          min: 0,
          max: 40,
        },
      },
      {
        type: 'ooo',
        vat: 'usno-6',
        value: {
          price: 1000,
          min: 0,
          max: 40,
        },
      },
      {
        type: 'ooo',
        vat: 'usno-15',
        value: {
          price: 600,
          min: 0,
          max: 40,
        },
      },
    ],
    transactionPropsList: [
      {
        type: 'ip',
        vat: 'osno',
        value: {
          price: 10,
          min: 0,
          max: 100,
        },
      },
      {
        type: 'ip',
        vat: 'usno-6',
        value: {
          price: 52,
          min: 0,
          max: 100,
        },
      },
      {
        type: 'ip',
        vat: 'usno-15',
        value: {
          price: 52,
          min: 0,
          max: 100,
        },
      },
      {
        type: 'ip',
        vat: 'patent',
        value: {
          price: 52,
          min: 0,
          max: 100,
        },
      },
      {
        type: 'ooo',
        vat: 'osno',
        value: {
          price: 60,
          min: 0,
          max: 100,
        },
      },
      {
        type: 'ooo',
        vat: 'usno-6',
        value: {
          price: 70,
          min: 0,
          max: 100,
        },
      },
      {
        type: 'ooo',
        vat: 'usno-15',
        value: {
          price: 52,
          min: 0,
          max: 100,
        },
      },
    ],
    paymentAccountPropsList: [
      {
        type: 'ip',
        vat: 'osno',
        value: {
          price: 1000,
          min: 0,
          max: 5,
        },
      },
      {
        type: 'ip',
        vat: 'usno-6',
        value: {
          price: 1000,
          min: 0,
          max: 5,
        },
      },
      {
        type: 'ip',
        vat: 'usno-15',
        value: {
          price: 1000,
          min: 0,
          max: 5,
        },
      },
      {
        type: 'ip',
        vat: 'patent',
        value: {
          price: 1000,
          min: 0,
          max: 5,
        },
      },
      {
        type: 'ooo',
        vat: 'osno',
        value: {
          price: 1000,
          min: 0,
          max: 5,
        },
      },
      {
        type: 'ooo',
        vat: 'usno-6',
        value: {
          price: 1000,
          min: 0,
          max: 5,
        },
      },
      {
        type: 'ooo',
        vat: 'usno-15',
        value: {
          price: 1000,
          min: 0,
          max: 5,
        },
      },
    ],
    server1cPropsList: [
      {
        type: 'ooo',
        vat: 'usno-6',
        value: {
          price: 1000,
          min: 0,
          max: 100,
        },
      },
    ],
  }),
  computed: {
    basePrice() {
      const findItem = this.basePriceList.find((item) => {
        const checked = item.type === this.type && item.vat === this.vat;
        return checked;
      });

      return (findItem) ? findItem.value : 0;
    },
    serviceRatio() {
      const findItem = this.serviceRatioList.find((item) => item.service === this.service);

      return (findItem) ? findItem.value : 0;
    },
    staffProps() {
      const findItem = this.staffPropsList.find((item) => {
        const checked = item.type === this.type && item.vat === this.vat;
        return checked;
      });

      return (findItem) ? findItem.value : null;
    },
    transactionProps() {
      const findItem = this.transactionPropsList.find((item) => {
        const checked = item.type === this.type && item.vat === this.vat;
        return checked;
      });

      return (findItem) ? findItem.value : null;
    },
    paymentAccountProps() {
      const findItem = this.paymentAccountPropsList.find((item) => {
        const checked = item.type === this.type && item.vat === this.vat;
        return checked;
      });

      return (findItem) ? findItem.value : null;
    },
    server1cProps() {
      const findItem = this.server1cPropsList.find((item) => {
        const checked = item.type === this.type && item.vat === this.vat;
        return checked;
      });

      return (findItem) ? findItem.value : null;
    },
    total() {
      const staff = this.staff * this.staffProps.price;
      const transaction = this.transaction * this.transactionProps.price;
      const paymentAccount = this.paymentAccount * this.paymentAccountProps.price;
      const server1c = (this.server1cProps) ? this.server1c * this.server1cProps.price : 0;
      return this.basePrice * this.serviceRatio + staff + transaction + paymentAccount + server1c;
    },
  },
};
</script>

<style lang="scss">
  .calc-block {
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
    color: #232323;
    background: #F1F5F9;
    border-radius: 32px;
    padding: 60px 90px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;

    p {
      width: initial;
      text-align: initial;
    }

    & > div:last-child {
      grid-column: 1/4;
      display: grid;
      grid-template-columns: max-content max-content;
      gap: 30px;
      align-items: center;

      @media (max-width: 1199px) {
        grid-column: 1/3;
      }

      @media (max-width: 720px) {
        grid-column: 1/2;
        grid-template-columns: 1fr;
        gap: 15px;
      }
    }

    & > div:nth-child(3) {

      @media (max-width: 1199px) {
        grid-column: 1/3;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 30px;
        align-items: flex-end;
      }

      @media (max-width: 720px) {
        grid-column: 1/2;
        grid-template-columns: 1fr;
        gap: 0;
      }
    }

    @media (max-width: 1399px) {
      padding: 60px;
    }

    @media (max-width: 1199px) {
      padding: 40px;
      grid-template-columns: 1fr 1fr;
      font-size: 22px;
    }

    @media (max-width: 720px) {
      padding: 30px 15px;
      font-size: 18px;
      border-radius: 20px;
      grid-template-columns: 1fr;
    }
  }

  .calc-mb-3 {
    margin-bottom: 15px;
  }

  .calc-mb-4 {
    margin-bottom: 30px;

    @media (max-width: 720px) {
      margin-bottom: 15px;
    }
  }
</style>
